import { Col } from '../components/grid'
import styled, { css, breakpoints } from '@xstyled/styled-components'

export const CirclePromo = styled(Col)`
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  display: flex;
  flex-direction: column;
  line-height: 1.3em;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: default;

  ${breakpoints({
    xs: css`
      background-size: 184px 184px;
      height: 184px;
      font-size: 0.7em;
    `,
    md: css`
      background-size: 160px 160px;
      height: 160px;
      font-size: 0.6em;
    `,
    lg: css`
      background-size: 230px 230px;
      height: 230px;
      font-size: 1.1em;
    `,
  })}

  & img {
    ${breakpoints({
    xs: css`
      width: 50%;
    `,
    md: css`
      width: 70%;
    `,
    lg: css`
      width: 100%;
    `,
  })}
  }

  & p {
    margin: 0;
    ${breakpoints({
    xs: css`
      padding: 2 6;
    `,
    md: css`
      padding: 1 4;
    `,
    lg: css`
      padding: 2 5;
    `,
  })}
  }

  & a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &:nth-child(n+3) {
    ${breakpoints({
    xs: css`
      margin-top: 3;
    `,
    md: css`
      margin-top: 0
    `,
  })}
  }
`

export default CirclePromo
