import React, { useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, Row, Col } from '../components/grid'
import PageHeader from '../components/page-header'
import styled, { Box, css, breakpoints } from '@xstyled/styled-components'
import { Text, H3, Emphasis } from '../components/text'
import Button from '../components/button'
import CirclePromo from '../components/circle-promo'
import HoverCirclePromo from '../components/hover-circle-promo'
import { navigate } from 'gatsby'

import mapPin from '../images/map-pin.svg'
import pinkCircle from '../images/pink-circle-solid.svg'
import fiilsLogo from '../images/fiils-logo.png'
import airsupplyLogo from '../images/airsupply-logo.png'
import allplantsLogo from '../images/allplants-logo.png'
import cosoundLogo from '../images/cosound-logo.svg'
import hekaLogo from '../images/heka-logo.svg'
import claimerLogo from '../images/claimer-logo.png'
import kalenditLogo from '../images/kalendit-logo.svg'
import mintagoLogo from '../images/mintago-logo.svg'
import vivacityLogo from '../images/vivacity-logo.png'
import circlLogo from '../images/circl-logo.svg'

import plabookLogo from '../images/playbook-logo.png'
import gametimeLogo from '../images/gametime-logo.svg'
import cafeXLogo from '../images/cafe-x-logo.svg'
import fitbodLogo from '../images/fitbod-logo.png'
import balloonLogo from '../images/balloon-logo.svg'
import xendooLogo from '../images/xendoo-logo.svg'
import toriiLogo from '../images/torii-logo.png'
import takeoffsLogo from '../images/joyne-logo.svg'
import undergroundcellaLogo from '../images/undergroundcella-logo.png'
import kudigoLogo from '../images/kudigo-logo.png'
import wirelesssocialLogo from '../images/wirelesssocial-logo.png'
import tableyetiLogo from '../images/tableyeti-logo.png'
import embargoLogo from '../images/embargo-logo.png'
import zwingsLogo from '../images/zwings-logo.png'
import slickLogo from '../images/slick-logo.png'
import hurreeLogo from '../images/huree-logo.svg'
import kaldaLogo from '../images/kalda-logo.svg'
import fanbaseLogo from '../images/fanbase-logo.png'
import naytalLogo from '../images/naytal-logo.png'
import plerkLogo from '../images/plerk-logo.svg'
import wednesdaysDomaineLogo from '../images/wednesdays-domaine.png'
import volunteeroLogo from '../images/volunteero-logo.svg'
import codeFirstGirlsLogo from '../images/code-girls-first-logo.png'

import honestMobileLogo from '../images/honest-mobile-logo.png'
import honestHealthLogo from '../images/honest-health-logo.png'
import blueprintLogo from '../images/blueprint-logo.png'
import pubxLogo from '../images/pubx-logo.png'
import guiderLogo from '../images/guider-logo.png'

import rootAiLogo from '../images/root-ai-logo.png'
import flumeLogo from '../images/flume-logo.png'
import techmateLogo from '../images/techmate-logo.png'
import tribuLogo from '../images/tribu-logo.png'

import zincLogo from '../images/zinc-logo.png'
import scanLogo from '../images/scan-logo.jpg'
import screenloopLogo from '../images/screenloop-logo.svg'
import blushLogo from '../images/blush-logo.svg'
import supercastLogo from '../images/supercast-logo.svg'
import dmnLogo from '../images/dmn-logo.png'
import sequelLogo from '../images/sequel-logo.svg'
import trumpetLogo from '../images/trumpet-logo.png'

// import Select from '../components/select'
// import SubHeader from '../components/sub-header'

const items = [
  {
    hideMap: true,
    country: 'Founded',
    items: [
      {
        logo: trumpetLogo,
        name: 'Trumpet',
        url: 'https://www.sendtrumpet.com/',
        types: ['fintech'],
        text: 'Supercharge your cold outreach, centralise the back-and-forth and get your deals done quicker.',
      },
      {
        logo: sequelLogo,
        name: 'Sequel',
        url: 'https://www.joinsequel.co/',
        types: ['fintech'],
        text:
          'A community of the world\'s best athletes investing in the world\'s best startups, making a positive impact.',
      },
      {
        logo: dmnLogo,
        name: 'DesignMyNight',
        url: 'https://www.designmynight.com/',
        types: ['fintech'],
        text: 'A nightlight discovery platform.',
        aquiredByText: 'Acquired by The Access Group 2018',
      },
    ]
  },
  {
    country: 'UK - Invested',
    items: [
      {
        logo: honestHealthLogo,
        name: 'Honest Health',
        url: 'https://www.honest-health.co/',
        types: ['fintech'],
        text: 'A vertical health platform, delivering targeted care at scale.',
        aquiredByText: 'Acquired by him & hers June 2021',
      },
      {
        logo: blueprintLogo,
        name: 'Blueprint',
        url: 'https://blueprint.store/',
        types: ['fintech'],
        text:
          'Reimagining how customers purchase and communicate with e-commerce brands.',
        aquiredByText: 'Acquired by Klayvio Nov 2022',
      },
      {
        logo: zwingsLogo,
        name: 'Zwings',
        url: 'https://www.zwings.co.uk/',
        types: ['fintech'],
        text:
          'A turnkey B2B and B2G micro-mobility solution providing e-bikes and e-scooters to councils, universities, developers & more.',
        aquiredByText: 'Acquired by Zeus Scooters Sept 2022',
      },
      {
        logo: naytalLogo,
        name: 'Naytal',
        url: 'https://naytal.uk/',
        types: ['fintech'],
        aquiredByText: 'Acquired by Maven 2023',
        text:
          'Instant access to women’s health experts, for support with pregnancy and postnatal needs.',
      },
      {
        logo: wirelesssocialLogo,
        name: 'Wireless Social',
        url: 'https://www.wireless-social.com/',
        types: ['fintech'],
        aquiredByText: 'Acquired by The Access Group 2024',
        text:
          'A leading provider of guest Wi-Fi, analytics, and marketing solutions to the UK Hospitality sector.',
      },
      {
        logo: airsupplyLogo,
        name: 'AirSupply',
        url: 'https://airsupply.org.uk/home',
        types: ['fintech'],
        text:
          'Connect schools and supply teachers direct, without the need for an agency. Quick, transparent and with lower cost than using a typical agency. ',
      },
      {
        logo: allplantsLogo,
        name: 'Allplants',
        url: 'https://allplants.com/',
        types: ['fintech'],
        text:
          'An award-winning planet-positive food brand and VC-backed B-Corp on a mission to build Earth’s most forward-thinking food company.',
      },
      {
        logo: hekaLogo,
        name: 'Heka',
        url: 'https://www.gosweat.com/',
        types: ['fintech'],
        text:
          'Revolutionising well-being in corporates. It allows companies to offer their employees a variety of activities in the well-being space.',
      },
      {
        logo: claimerLogo,
        name: 'Claimer',
        url: 'https://www.claimer.io/',
        types: ['fintech'],
        text:
          'Digitising the world of R&D tax credits. Claimer brings it to the masses, online, cheaper and saves startups and companies a lot of cash.',
      },
      {
        logo: kalenditLogo,
        name: 'Kalendit',
        url: 'https://www.kalendit.com/',
        types: ['fintech'],
        text:
          'Connects nurseries with the nearest available workers to cover the required shifts in no delay: A lot cheaper than traditional agencies.',
      },
      {
        logo: mintagoLogo,
        name: 'Mintago',
        url: 'https://www.mintago.com/',
        types: ['fintech'],
        text:
          'An end-to-end financial wellbeing solution that automatically helps employees earn, spend and save better. From the Founder of Perkbox.',
      },
      {
        logo: slickLogo,
        name: 'Slick',
        url: 'https://www.getslick.com/  ',
        types: ['fintech'],
        text:
          'Bringing salons the next generation in appointment booking software, stock management and customer engagement',
      },
      {
        logo: hurreeLogo,
        name: 'Embargo',
        url: 'http://www.hurree.co ',
        types: ['fintech'],
        text:
          'Hurree is the marketing automation platform that categorizes your audience based on all their interactions with your brand.',
      },
      {
        logo: honestMobileLogo,
        name: 'Honest Mobile',
        url: 'https://honestmobile.co.uk/',
        types: ['fintech'],
        text: 'Bringing the UX of Monzo and eco-credentials of Bulb, to Telco.',
        width: {
          xs: 0.5,
          lg: 0.4,
        },
      },
      {
        logo: pubxLogo,
        name: 'PubX',
        url: 'https://pubx.ai/',
        types: ['fintech'],
        text:
          'Using Artificial Intelligence to enable digital publishers to thrive.',
      },
      {
        logo: guiderLogo,
        name: 'Guider',
        url: 'https://www.guider-ai.com/',
        types: ['fintech'],
        text:
          'Creating the best personal-development platform for employees worldwide, with mentoring, coaching & advising at its core.',
      },
      {
        logo: fanbaseLogo,
        name: 'Fanbase',
        url: 'https://www.fanbaseclub.com/',
        types: ['fintech'],
        text: 'Digitising the experience between sports teams and their fans.',
        width: {
          xs: 0.3,
          lg: 0.3,
        },
      },
      {
        logo: kaldaLogo,
        name: 'Kalda',
        url: 'https://kalda.co/',
        types: ['fintech'],
        text:
          "World's first mental health wellbeing app for LGBTQIA+ community.",
      },
      {
        logo: zincLogo,
        name: 'Zinc',
        url: 'https://zinc.work/',
        types: ['fintech'],
        text:
          'Automated background and reference checks for speedier hiring decisions.',
      },
      {
        logo: scanLogo,
        name: 'Scan',
        url: 'https://scan.com/',
        types: ['fintech'],
        text:
          'Find a local private MRI scanning centre near you and book online in minutes.',
      },
      {
        logo: screenloopLogo,
        name: 'Screenloop',
        url: 'https://www.screenloop.com/',
        types: ['fintech'],
        text:
          'An Interview Intelligence platform that helps businesses create great interviews at scale.',
      },
      {
        logo: vivacityLogo,
        name: 'Viva City',
        url: 'https://www.vivacityapp.com/',
        types: ['fintech'],
        text:
          'Helping International cities become world leading Chinese friendly destinations in the field of hospitality, hotels and infrastructure. ',
      },
      {
        logo: tableyetiLogo,
        name: 'Table yeti',
        url: 'https://tableyeti.com/',
        types: ['fintech'],
        text:
          'Allows diners to quickly and easily pay for their meal at the table - splitting the bill, tipping and leaving feedback.',
      },
      {
        logo: embargoLogo,
        name: 'Embargo',
        url: 'https://www.embargoapp.com/',
        types: ['fintech'],
        text:
          'Digitising loyalty in the hospitality space with a full-service software and beacon tech; track repeat visits and market to your most loyal.',
      },
      {
        logo: wednesdaysDomaineLogo,
        name: 'Wednesdays Domaine',
        url: 'https://wednesdaysdomaine.com/',
        types: ['fintech'],
        text:
          'Alcohol free wine.',
      },
      {
        logo: volunteeroLogo,
        name: 'Volunteero',
        url: 'https://www.volunteero.org/',
        types: ['fintech'],
        text:
          'Save time managing volunteers whilst improving your volunteer engagement and retention.',
      },
      {
        logo: codeFirstGirlsLogo,
        name: 'Code First Girls',
        url: 'https://codefirstgirls.com/ ',
        types: ['fintech'],
        text:
          'Eliminating the tech diversity gap by providing free education and opportunities to women and non-binary people.',
      },
    ],
  },
  {
    country: 'USA - Invested',
    items: [
      {
        logo: rootAiLogo,
        name: 'Root AI',
        url: 'https://root-ai.com/',
        types: ['fintech'],
        text:
          'Creating the intelligent robots that will help growers build the farms of the future.  ',
        aquiredByText: 'Acquired by AppHarvest April 2021',
        width: {
          xs: 0.8,
          lg: 0.4,
        },
      },
      {
        logo: plabookLogo,
        name: 'Playbook',
        url: 'https://www.playbookapp.io/',
        types: ['fintech'],
        text:
          'The first marketplace for trainers, health & wellness professionals turning their workouts and coaching into a membership business',
      },
      {
        logo: gametimeLogo,
        name: 'Gametime',
        url: 'https://gmtm.com/',
        types: ['fintech'],
        text:
          'Gametime is a web and mobile platform that connects Athletes, Fans, and College Programs.',
      },
      {
        logo: cafeXLogo,
        name: 'Cafe X',
        url: 'https://cafexapp.com/',
        types: ['fintech'],
        text:
          'Designs, manufactures and operates Robotic Coffee Bars that serve specialty coffee, tea, and more.',
      },
      {
        logo: fitbodLogo,
        name: 'Fitbod',
        url: 'https://www.fitbod.me/',
        types: ['fintech'],
        text:
          'A subscription service offering personalized fitness plans for gym-goers and home exercisers, with over 90 million exercises logged. ',
      },
      {
        logo: balloonLogo,
        name: 'Balloon',
        url: 'https://getballoon.com/',
        types: ['fintech'],
        text:
          'An insight mobility platform that solves systemic organizational issues like cognitive biases and group dynamics. ',
      },
      {
        logo: xendooLogo,
        name: 'Xendoo',
        url: 'https://www.xendoo.com/',
        types: ['fintech'],
        text:
          'A fintech company in the online accounting and bookkeeping space serving small businesses.',
      },
      {
        logo: toriiLogo,
        name: 'Torii',
        url: 'https://www.torii.properties/',
        types: ['fintech'],
        text:
          'Buy a property on your phone, save thousands and with a full support team. Home buying for the next generation.',
      },
      {
        logo: flumeLogo,
        name: 'Flume',
        url: 'https://flumewater.com/',
        types: ['fintech'],
        text:
          'Helps customers save water, save money and protect their home.  ',
      },
      {
        logo: techmateLogo,
        name: 'Techmate',
        url: 'https://www.techmate.com/',
        types: ['fintech'],
        text:
          'Simple, on-demand tech support for small businesses and startups.',
      },
      {
        logo: tribuLogo,
        name: 'Tribu',
        url: 'https://www.tribu.ai/',
        types: ['fintech'],
        text:
          'Advanced platform that automates level 1 IT support issues using AI-powered interface.',
      },
      {
        logo: blushLogo,
        name: 'Blush',
        url: 'https://blush.design/',
        types: ['fintech'],
        text:
          'Building tools for creators to build NFTs - Canva for NFTs.',
      },
      {
        logo: supercastLogo,
        name: 'Supercast',
        url: 'https://www.supercast.com/',
        types: ['fintech'],
        text:
          'Allowing podcasters to activate subscriptions and recurring revenue and owning their data.',
      },
    ],
  },
  {
    country: 'Latam - Invested',
    items: [
      {
        logo: plerkLogo,
        name: 'Plerk',
        url: 'https://www.plerk.io/',
        types: ['fintech'],
        aquiredByText: 'Acquired by Minu 2023',
        text:
          "Creating benefit plans for teams to improve a company's work culture.",
      },
    ],
  },
  {
    country: 'Africa - Invested',
    items: [
      {
        logo: kudigoLogo,
        name: 'KudiGO',
        url: 'https://www.kudigo.com/',
        types: ['fintech'],
        text:
          'A mobile-first complete business suite which integrates a retail engine, an accounting engine and payment processing for African retail businesses.',
      },
    ],
  },
]

const MapPin = styled.span`
  background: url(${mapPin});
  width: 1em;
  height: 1em;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: text-top;
`

const SecondaryCirclePromo = styled(HoverCirclePromo)`
  background-image: url(${pinkCircle});
  margin-bottom: 3;
  color: black;

  & img {
    width: 100%;
    max-width: 150px;
  }
`

const CtaButton = styled(Button)`
  margin: 5 auto;
`

const RaisingFundsButton = styled(Button)`
  margin: 0 auto;
`

const StyledEmphasis = styled(Emphasis)(
  () => css`
    line-height: 1.6;
    ${breakpoints({
    xs: css`
        font-size: 20px;
      `,
    md: css`
        font-size: 22px;
      `,
    lg: css`
        font-size: 28px;
      `,
  })}
  `
)
const Portfolio = () => {
  const [filter, setFilter] = useState()

  const filteredItems = () =>
    items.map((item) => {
      item.investments = item.items.filter(
        (portfolioItem) =>
          !filter ||
          (portfolioItem.types && portfolioItem.types.includes(filter))
      )

      return item
    })

  return (
    <>
      <SEO title="Our Companies" />
      <Layout>
        <PageHeader>Our Companies</PageHeader>

        <Container>
          <Row>
            <Col col={1}>
              <Box
                display="flex"
                textAlign="center"
                justifyContent="center"
                my={5}
              >
                <Text>
                  <StyledEmphasis>
                    Welcome to our family of startups. These founders are brave,
                    bold and tackling exciting challenges in today’s world. We
                    are super excited to be working alongside them to help build
                    their dream company.
                  </StyledEmphasis>
                </Text>
              </Box>
            </Col>
          </Row>
        </Container>
        <Box backgroundColor="primary" pb="5">
          <Container>
            {/* <Select
              onChange={(event) => {
                setFilter(event.target.value)
              }}
              value={filter}
            >
              <option value="">
                Search...
              </option>
              <option value="fintech">Fintech</option>
              <option value="hospitaility">Hospitality</option>
            </Select> */}
            {filteredItems().map((item, idx) => (
              <Box key={idx}>
                <Row>
                  <Col col="1">
                    <Text color="white" mb="4">
                      {item.hideMap !== true && <MapPin />}
                      {item.country}
                    </Text>
                  </Col>
                </Row>
                <Row mb={{ xs: 3, md: 4, lg: 5 }}>
                  {item.investments.map((portfolioItem, pix) => (
                    <SecondaryCirclePromo
                      key={pix}
                      col={{ xs: 2 / 4, md: 2 / 8, lg: 3 / 12 }}
                      onClick={() => window.open(portfolioItem.url, '_blank')}
                      style={{ cursor: 'pointer' }}
                      logoProps={{
                        width: portfolioItem.width ?? 1,
                      }}
                      logo={
                        <img
                          src={portfolioItem.logo}
                          alt={portfolioItem.name}
                        />
                      }
                      text={<p>{portfolioItem.text}</p>}
                      aquiredByText={portfolioItem.aquiredByText}
                    />
                  ))}
                  {item.investments.length === 0 && (
                    <Col col={1}>
                      <Text color="white">Nothing found</Text>
                    </Col>
                  )}
                </Row>
              </Box>
            ))}
          </Container>
        </Box>
        <Container
          pt={{ xs: 5, md: 6, lg: 7 }}
          pb={{ xs: 4, md: 5, lg: 6 }}
          textAlign="center"
        >
          <Row>
            <Col col={12 / 12}>
              <Text>
                Interested in hearing about future fundraises for companies in
                our portfolio?
              </Text>
              <CtaButton onClick={() => navigate('/contact-us/')}>
                Get in touch
              </CtaButton>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Portfolio
