import React, { useState } from 'react'
import styled, { css, Box, useUp } from '@xstyled/styled-components'
import CirclePromo from './circle-promo'
import greenCircle from '../images/green-circle-solid.svg'
import { breakpoints } from '@xstyled/system'

const StyledHoverCirclePromo = styled(CirclePromo)(
  () =>
    css`
      background-image: url(${greenCircle});
      width: 100%;
      & img {
        width: 100%;
        max-width: 150px;
      }
    `
)

const StyledExited = styled(Box)(
  () =>
    css`
      line-height: 1.1rem;
      flex: 3;
      font-size: 0.9rem;

      ${breakpoints({
        xs: css`
          padding-top: 0;
        `,
        md: css`
          padding-top: 3;
        `,
        lg: css``,
      })}
    `
)

const StyledExitBadge = styled.div(
  () =>
    css`
      background-color: primary;
      color: inputGray;
      width: auto;
      display: inline-block;
      padding-left: 2;
      padding-right: 2;
      font-weight: bold;
      border-radius: 5px;
      font-size: 0.8rem;
      text-transform: uppercase;
      line-height: 1.2rem;
      align-self: flex-end;
      margin-bottom: 1;
    `
)

const LogoContainer = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${breakpoints({
    xs: css`
      max-width: 120px;
    `,
    md: css`
      max-width: 150px;
    `,
    lg: css`
      max-width: 150px;
    `,
  })}
`

export const HoverCirclePromo = ({
  logo,
  logoProps = {},
  text,
  aquiredByText,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const upMd = useUp('md')

  const handleMouseEnter = () => {
    upMd && setIsHovered(true)
  }

  const handleMouseLeave = () => {
    upMd && setIsHovered(false)
  }

  return (
    <StyledHoverCirclePromo
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      {...props}
    >
      {isHovered ? (
        <Box>{text}</Box>
      ) : (
        <LogoContainer {...logoProps}>
          {aquiredByText && (
            <Box flex={2} display="flex" alignItems="center" mb={1}>
              <StyledExitBadge>Exited</StyledExitBadge>
            </Box>
          )}
          <Box flex={3} display="flex" alignItems="center">
            {logo}
          </Box>
          {aquiredByText && <Exited text={aquiredByText} />}
        </LogoContainer>
      )}
    </StyledHoverCirclePromo>
  )
}

const Exited = ({ text }) => {
  return <StyledExited>{text}</StyledExited>
}

export default HoverCirclePromo
